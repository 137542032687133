import React, { FunctionComponent } from "react";

const TermsOfService: FunctionComponent = () => {
  return (
    <div>
      <div className="w-full">
        <div className="flex bg-white ">
          <div className="flex pt-16 lg:items-center text-left md:text-left px-8 md:px-28 lg:w-4/5">
            <div>
              <p className="text-3xl font-semibold text-bytestrone-blue md:text-4xl">
                Terms of Service
              </p>
              <p className="mt-2 text-sm text-gray-900 md:text-base">
                The following Terms and Conditions govern the use of each of the
                websites located at, or linked to, the URLs{" "}
                <a className="text-blue-500" href="https://bytestrone.com/">
                  bytestrone.com
                </a>{" "}
                and the services that may be offered in the site; the
                information provided in the sites and the ability to register
                into our database, receive newsletters and promotional emails;
                as well as any related links (collectively, our
                &quot;Sites&quot;).
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="flex bg-white">
        <div className="flex flex-col items-center text-left md:text-left px-8 pb-12 md:px-28 lg:w-4/5 ">
          <p className="mt-2 text-sm text-gray-900 md:text-base space-y-3">
            <p>Please read the following Terms and Conditions carefully.</p>
            <p>
              By using any one of our Sites, you understand and expressly agree
              to be legally bound by these Terms and Conditions and to follow
              these Terms and Conditions and all applicable laws and regulations
              governing our Sites.
            </p>

            <p>
              The Terms and Conditions shall supersede any subsequent terms or
              conditions included with any purchase order, whether or not such
              terms or conditions are signed by Bytestrone
              (&quot;Bytestrone,&quot; &quot;we&quot; or &quot;us&quot;).
            </p>

            <p>
              We reserve the right to change these Terms and Conditions at any
              time, effective immediately upon posting on our Sites. If you
              violate these Terms and Conditions, we may terminate your use of
              the Sites, bar you from future use of the Sites, and/or take
              appropriate legal action against you.
            </p>

            <h2>Copyright and Trademark Notice</h2>

            <p>
              The contents of all material available on our Sites are
              copyrighted by Bytestrone unless otherwise indicated. All rights
              are reserved and content may not be reproduced, downloaded,
              disseminated, or transferred, in any form or by any means, except
              with the prior written agreement of Bytestrone or as indicated
              below;
            </p>

            <p>
              Permitted Use: Users may download pages or other content for their
              own personal use on a single computer, but no part of such content
              may be otherwise or subsequently reproduced, downloaded,
              disseminated, or transferred, in any form or by any means, except
              with the prior written agreement of, and with express attribution
              to, Bytestrone. You agree that you are only authorized to visit,
              view and to retain a copy of pages of the Sites for your own
              personal use, and that you shall not duplicate, download, publish,
              modify or otherwise distribute the material on the Sites for any
              purpose other than for personal use, unless otherwise specifically
              authorized by us to do so. You also agree not to deep-link to the
              site for any purpose, unless specifically authorized by us to do
              so.
            </p>

            <h2>General Legal Notice and Liability Disclaimer</h2>

            <p>
              We make available our Sites and the information and services
              contained herein “as is”, while Bytestrone makes every effort to
              present accurate and reliable information on our Sites, Bytestrone
              does not endorse, approve or certify such information, nor does it
              guarantee the accuracy, completeness, efficacy, or timeliness of
              such information. Use of such information is voluntary, and
              reliance on it should only be undertaken after an independent
              review by qualified experts.
            </p>

            <p>
              Reference herein to any specific commercial product, process or
              service does not constitute or imply endorsement, recommendation
              or favoring by Bytestrone.
            </p>

            <p>
              At certain places on this site, live &quot;links&quot; to other
              Web sites can be accessed. Such external sites contain information
              created, published, maintained, or otherwise posted by
              institutions or organizations independent of Bytestrone.
              Bytestrone does not endorse, approve, certify, or control these
              external sites and does not guarantee the accuracy, completeness,
              efficacy, or timeliness of information located at such sites. Use
              of any information obtained from such sites is voluntary, and
              reliance on it should only be undertaken after an independent
              review by qualified experts.
            </p>

            <p>
              Bytestrone assumes no responsibility for consequences resulting
              from use of the information contained herein, or from use of the
              information obtained at linked sites, or in any respect for the
              content of such information. Bytestrone is not responsible for,
              and expressly disclaims all liability for, damages of any kind
              arising out of use, reference to, reliance on, or performance of
              such information.
            </p>

            <h2>Privacy Policy</h2>

            <p>
              Bytestrone values individual privacy and we want to give our
              Site´s visitors the opportunity to know what information we
              collect about them and how it is treated by us. If you would like
              more information, please visit our privacy policy page.{" "}
            </p>

            <h2>Severability</h2>

            <p>
              The invalidity or unenforceability of any particular provision of
              this Policy shall not affect the remaining provisions hereof, and
              this Policy shall be construed in all respects as if such invalid
              or unenforceable provision had been omitted.
            </p>
          </p>
        </div>
      </div>{" "}
    </div>
  );
};

export default TermsOfService;
